import {projects} from "./projects";
import { useContext } from "react";
import { LanguageContext } from "../utils/context";

const Portfolio = () => {
    const allProjects = projects;
    const { language } = useContext(LanguageContext);

    return ( 
        <section id="portfolio">
            {/* <h1><MultilingualContent contentID="portfolioTitle" /></h1>
            <p><MultilingualContent contentID="portfolioDesc" /></p>
            <Divider /> */}
            <h2>Portfolio</h2>
            {/* {language === "french" ? (<p className="mobile-tablet">Cliquez sur les images pour plus de détail</p>) : (<p className="mobile-tablet">Click on the images for more details</p>)} */}
            <div className="portfolio-items">
                {allProjects.map((project, index) => {
                    return (
                        
                        <a href={project.link} target="_blank" rel="noreferrer noopener" aria-label={`Lien vers le projet ${project.titleEn}`} className="portfolio-item" key={project.id}>
                            <div className="portfolio-item-frame">
                                {language === "french" ? <img src={project.image} alt={project.titleFr} /> : <img src={project.image} alt={project.titleEn} />}
                            </div>
                            <div className="portfolio-item-description">
                                <div className="portfolio-item-year">
                                    <p>{project.year}</p>
                                </div>
                                <div className="portfolio-item-text">
                                    {language === "french" ? 
                                        <h3 className="portfolio-item-title">{project.titleFr}</h3>
                                         : 
                                         <h3 className="portfolio-item-title">{project.titleEn}</h3>
                                    }
                                    <h4>{project.client}</h4>
                                    <p className="portfolio-item-technologies">{project.technologies}</p>
                                    {/* {language === "french" ? <p>Rôle : {project.roleFr}</p> : <p>Role : {project.roleEn}</p>} */}
                                    {/* <div className="portfolio-item-link">
                                {language === "french" ? 
                                <a href={project.link} target="_blank" rel="noreferrer noopener" aria-label={`Lien vers le projet ${project.titleFr}`} > Voir le site <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="fa" /></a>
                                : 
                                <a href={project.link} target="_blank" rel="noreferrer noopener" aria-label={`Lien vers le projet ${project.titleEn}`} > See website <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="fa" /></a>
                                }
                            </div>   */}
                                </div>
                            </div>  
                            </a>
                    )
                })
            }
            </div>
            {/* <div className="portfolio-items">
                {allProjects.map((project, index) => {
                    return (
                        <div className="portfolio-item" key={project.id}>
                            {language === "french" ? <img src={project.image} alt={project.titleFr} /> : <img src={project.image} alt={project.titleEn} />}
                            
                            <div className="hover-information">
                                <div className="top-portfolio-item">
                                    {language === "french" ? <h3>{project.titleFr}</h3> : <h3>{project.titleEn}</h3>}
                                </div>
                                
                                {language === "french" ? 
                                (
                                    <div className="bottom-portfolio-item">
                                        <button className="btn-link" aria-label={`Lien vers le projet ${project.titleFr}`}><a href={project.link} target="_blank" rel="noreferrer noopener" aria-label={`Lien vers le projet ${project.titleFr}`} ><FontAwesomeIcon icon={faArrowUpRightFromSquare} /></a></button>
                                        <div className="bottom-right">
                                            <p className="technologies bold">{project.technologies}</p>
                                            <p className="description">{project.descriptionFr}</p>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="bottom-portfolio-item">
                                    <button className="btn-link" aria-label={`Lien vers le projet ${project.titleEn}`}><a href={project.link} target="_blank" rel="noreferrer noopener" aria-label={`Lien vers le projet ${project.titleEn}`} ><FontAwesomeIcon icon={faArrowUpRightFromSquare} /></a></button>
                                    <div className="bottom-right">
                                        <p className="technologies bold">{project.technologies}</p>
                                        <p className="description">{project.descriptionEn}</p>
                                    </div> 
                                    </div>   
                                )}
                            </div>
                        </div>
                    )
                })
            }
            </div> */}
        </section>
     );
}
 
export default Portfolio;