// import logo from './logo.svg';
import { useState } from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './components/header';
import Footer from './components/footer';
import Home from './pages/home';
import Portfolio from './components/portfolio';
import About from './components/about';
import './utils/style/index.scss';
import ScrollToTop from "./utils/helpers/ScrollToTop";
import PrivacyPolicy from "./pages/privacypolicy";
// import Services from "./pages/services";
import NotFound from "./pages/notFound";
import { LanguageContext } from "./utils/context";

function App() {
  // const userLang = navigator.language || navigator.userLanguage;
  // console.log("User's preferred language is: " + userLang);
  const [language, setLanguage] = useState("french");
  function toggleLanguage() {
    setLanguage((language) => ( language === "french" ? "english" : "french"));
  }
  return (
    <>
      <LanguageContext.Provider value={{ language, toggleLanguage }}>
        <BrowserRouter>
            <ScrollToTop />
              <Header />
              <Routes>
                <Route path="*" element={<NotFound />} />
                <Route path="/" element={<Home />} />
                {/* <Route path="/web-services" element={<Services />} /> */}
                <Route path="/portfolio" element={<Portfolio />} />
                <Route path="/about" element={<About />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              </Routes>
              <Footer />
        </BrowserRouter>
      </LanguageContext.Provider>
    </>
  );
}

export default App;