import { useContext } from "react";
import { Link } from "react-router-dom";
import avatar from "../assets/avatar.png";
import bubble from "../assets/bubble1.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import MultilingualContent from "../components/multilingualContent";
import { LanguageContext } from "../utils/context";

const Home = () => {
    const { language } = useContext(LanguageContext);

    return ( 
    <div>
    <section id="introduction">
        <div className="section-text">
            <div className="main-header"><MultilingualContent contentID="mainTitle" /> <span className="main-header-overlined"><MultilingualContent contentID="webDev" /></span></div>
            <p>
                <MultilingualContent contentID="mainDesc" /> 
                <span className="is-overlined"><MultilingualContent contentID="responsives" /></span>, 
                <span className="is-overlined"><MultilingualContent contentID="dynamic" /></span>
                <MultilingualContent contentID="and" /> 
                <span className="is-overlined"><MultilingualContent contentID="accessible" /></span> .<br />
            </p>
            <div className="home-links">
                <Link to="/portfolio" className="link">
                    <FontAwesomeIcon icon={faCaretRight} className="fa fa-caret-right" /> 
                    <p className="desktop-show"><MultilingualContent contentID="mainPortfolioLink" /></p>
                </Link> 
                <br />
                <Link to="/about" className="link">
                    <FontAwesomeIcon icon={faCaretRight} className="fa fa-caret-right" /> 
                    <p className="desktop-show"><MultilingualContent contentID="mainAboutLink" /></p>
                </Link> 
            </div>

        </div>
        <div id="right-section">
            
            <div className="section-image">
            <img 
                src={bubble}
                alt="graphic bubble with coding icon"
                className="bubble"
            />
            <img 
                src={avatar} 
                alt="Camille Herpin" 
                className="image"
            />
            </div>
        </div>
    </section>
    {language === "french" ? 
    (
    <section id="services">
        <div className="services-header">
        <h2>Conception de sites web</h2>
        {/* <Link to="/web-services" className="link">
            <FontAwesomeIcon icon={faCaretRight} className="fa fa-caret-right" /> 
            <p className="desktop-show">Services en détail</p>
        </Link>  */}
        </div>
        <div className="cards">
        <article className="card">
            <p className="number">1</p>
            <h3>Intégration web sur mesure</h3>
            <p>Intégration web & mobile de sites vitrine, applications web, sites d'e-commerce, etc.</p>
        </article>
        <article className="card">
            <p className="number">2</p>
            <h3>Structure moderne</h3>
            <p>Utilisation d'une structure moderne, modulable et dynamique.</p>
        </article>
        <article className="card">
            <p className="number">3</p>
            <h3>Expérience Utilisateur</h3>
            <p>Créations d'animations pour dynamiser les interactions.</p>
        </article>
        <article className="card">
            <p className="number">4</p>
            <h3>Responsive Design</h3>
            <p>Adaptation du site à toute taille d'écran.</p>
        </article>
        <article className="card">
            <p className="number">5</p>
            <h3>Référencement</h3>
            <p>Optimisation du SEO pour être trouvé facilement sur les moteurs de recherche.</p>
        </article>
        <article className="card">
            <p className="number">6</p>
            <h3>Accessibilité</h3>
            <p>Respect des bonnes pratiques d'accessibilité du WCAG</p>
        </article>
        </div>
    </section>
    ) : (
        <section id="services">
            <div className="services-header">
                <h2>Websites Creation</h2>
                {/* <Link to="/web-services" className="link">
                    <FontAwesomeIcon icon={faCaretRight} className="fa fa-caret-right" /> 
                    <p className="desktop-show">More info on services</p>
                </Link>  */}
            </div>
        <div className="cards">
        <article className="card">
        <p className="number">1</p>
            <h3>Web Integration</h3>
            <p>Converting your own design to a fully functional web application.</p>
        </article>
        <article className="card">
        <p className="number">2</p>
            <h3>Modern Structure</h3>
            <p>Using modern, modular and dynamic structure.</p>
        </article>
        <article className="card">
        <p className="number">3</p>
            <h3>User Experience</h3>
            <p>Creating animations to dynamise your website interactions.</p>
        </article>
        <article className="card">
        <p className="number">4</p>
            <h3>Responsive Design</h3>
            <p>Web apps adapt to all screen sizes and resolutions.</p>
        </article>
        <article className="card">
        <p className="number">5</p>
            <h3>SEO</h3>
            <p>Search Engine Optimization to be easily found on search engines.</p>
        </article>
        <article className="card">
        <p className="number">6</p>
            <h3>Accessibility</h3>
            <p>Respecting WCAG good practices for website accessibility.</p>
        </article>
        </div>
    </section>
    )
    }
    </div> );
}
 
export default Home;