import LionelHerpin from "../assets/portfolio/012lionelherpin.png";
import LensOfTheFree from "../assets/portfolio/011lensofthefree.png";
import PotteryClass from "../assets/portfolio/010pottery.png";
import BikeMitts from "../assets/portfolio/009bikemitts.png";
import PortfolioSite from "../assets/portfolio/008portfolio.png";
import Project7 from "../assets/portfolio/007.png";
import Project6 from "../assets/portfolio/006.png";
import Project5 from "../assets/portfolio/005.png";
import Project4 from "../assets/portfolio/004.png";
import Project3 from "../assets/portfolio/003.png";
import Project2 from "../assets/portfolio/002.png";

export const projects =
[
    {
        "id": "lionelherpin",
        "image": LionelHerpin,
        "titleFr": "Création d'un site full-stack pour un artiste peintre",
        "titleEn": "Creating a full-stack website for an artist",
        "technologies": "ReactJS / NodeJS / MongoDB",
        "descriptionFr": "",
        "descriptionEn": "",
        "link": "https://lionelherpin.fr",
        "year":2023,
        "client": "Lionel Herpin",
        "roleFr": "Développeuse Full-Stack",
        "roleEn": "Full-Stack Developer"
    },
    {
        "id": "lensofthefree",
        "image": LensOfTheFree,
        "titleFr": "Création d'un blog de voyage Wordpress multilingue",
        "titleEn": "Creating a multilingual travel blog with Wordpress",
        "technologies": "Wordpress / Hosting",
        "descriptionFr": "",
        "descriptionEn": "",
        "link": "https://lensofthefree.com",
        "year":2023,
        "client": "Personnel",
        "roleFr": "Développeuse Wordpress",
        "roleEn": "Wordpress Developer"
    },
    {
        "id": "pottery",
        "image": PotteryClass,
        "titleFr": "Développement full-stack d'un site pour une association",
        "titleEn": "Full-Stack website development for a nonprofit organization",
        "technologies": "ReactJS / NodeJS / MongoDB",
        "descriptionFr": "",
        "descriptionEn": "",
        "link": "https://github.com/kamoheel/site-poterie",
        "year":2023,
        "client": "Argilement",
        "roleFr": "Développeuse Full-Stack",
        "roleEn": "Full-Stack Developer"
    },
    {
        "id": "BikeMitts",
        "image": BikeMitts,
        "titleFr": "Développement front-end d'un site vitrine",
        "titleEn": "Front-End development of a showcase site",
        "technologies": "ReactJS / Sass / JavaScript",
        "descriptionFr": "Développement front-end du site vitrine de Bike Mitts avec React et Sass.",
        "descriptionEn": "Front-end development of Bike Mitts website with React and Sass.",
        "link": "https://bikemitts.ca",
        "year":2023,
        "client": "Bike Mitts",
        "roleFr": "Développeuse Front-End",
        "roleEn": "Front-End Developer"
    },
    {
        "id": "PortfolioSite",
        "image": PortfolioSite,
        "titleFr": "Développement Front-End de mon Site Web",
        "titleEn": "Front-End development of my website",
        "technologies": "ReactJS / Sass / JavaScript",
        "descriptionFr": "Développement front-end de mon site vitrine avec React et Sass.",
        "descriptionEn": "Front-end development of my portfolio website with React and Sass.",
        "link": "https://github.com/kamoheel/portfolio-camille-h",
        "year":2022,
        "client": "Camille Herpin",
        "roleFr": "Développeuse Front-End",
        "roleEn": "Front-End Developer"
    },
    {
        "id": "Project7",
        "image": Project7,
        "titleFr": "Créer un réseau social d'entreprise",
        "titleEn": "Create a Social Network",
        "technologies": "ReactJS / NodeJS / MongoDB",
        "descriptionFr": "Développement full-stack d'un réseau social pour une entreprise fictive, avec des fonctionnalités d'authentification, de publication et de stockage de données.",
        "descriptionEn": "Full-Stack building of a social network for an imaginary company, with authentication, posting and storage functions.",
        "link": "https://github.com/kamoheel/ch-p7-groupomania",
        "year":2022,
        "client": "Groupomania",
        "roleFr": "Développeuse Full-Stack - Projet de formation",
        "roleEn": "Full-Stack Developer - Training project"
    },
    {
        "id": "Project6",
        "image": Project6,
        "titleFr": "Construire une API sécurisée",
        "titleEn": "Build a secured API",
        "technologies": "NodeJS / Express / MongoDB ",
        "descriptionFr": "Création d'une API pour une application de notation de sauces piquantes, opérations CRUD pour l'authentification, la publication, le like/dislike et stockage des données.",
        "descriptionEn": "Creating an API for a hot sauce reviews application, CRUD operations for authentication, posting, like/dislike and storage functions.",
        "link": "https://github.com/kamoheel/ch-p6-piiquante",
        "year":2022,
        "client": "Piiquante",
        "roleFr": "Développeuse Full-Stack - Projet de formation",
        "roleEn": "Full-Stack Developer - Training project"
    },
    {
        "id": "Project5",
        "image": Project5,
        "titleFr": "Intégration d'éléments d'API",
        "titleEn": "API elements integration",
        "technologies": "API / JavaScript / NodeJS ",
        "descriptionFr": "Implémentation du site Kanap de manière dynamique: intégration dynamique des éléments de l'API, mise en place d'un plan de test d'acceptation.",
        "descriptionEn": "Dynamically embed the Kanap website: integrate API elements, building an acceptance test.",
        "link": "https://github.com/kamoheel/ch-p5-kanap",
        "year":2022,
        "client": "Kanap",
        "roleFr": "Développeuse Full-Stack - Projet de formation",
        "roleEn": "Full-Stack Developer - Training project"
    },
    {
        "id": "Project4",
        "image": Project4,
        "titleFr": "Optimiser un site web existant",
        "titleEn": "Optimize an existing website",
        "technologies": "SEO / KPI / WCAG ",
        "descriptionFr": "Optimiser le référencement, les performances et l'accessibilité du site de l'agence web La Panthère afin de faire repartir l'activité.",
        "descriptionEn": "Search Engine Optimization, optimize performances and accessibility for a marketing agency's website.",
        "link": "https://github.com/kamoheel/camille-p4-lapanthere",
        "year":2022,
        "client": "La Panthère",
        "roleFr": "Développeuse Front-End - Projet de formation",
        "roleEn": "Front-End Developer - Training project"
    },
    {
        "id": "Project3",
        "image": Project3,
        "titleFr": "Ajouter des animations CSS",
        "titleEn": "Integrate CSS animations",
        "technologies": "Sass / HTML5 / CSS3",
        "descriptionFr": "Développer un site qui référence les menus de restaurants gastronomique: animations CSS modernes, mobile-first.",
        "descriptionEn": "Implement the mobile version of this foodtech website with CSS animations.",
        "link": "https://kamoheel.github.io/ch-p3-ohmyfood/",
        "year":2022,
        "client": "OhMyFood",
        "roleFr": "Développeuse Front-End - Projet de formation",
        "roleEn": "Front-End Developer - Training project"
    },
    {
        "id": "Project2",
        "image": Project2,
        "titleFr": "Intégrer une maquette en site web",
        "titleEn": "Turn Mock-ups Into a Web Page",
        "technologies": "HTML5 / CSS3",
        "descriptionFr": "Intégrer un site d'hébergement et d'activités en HTML & CSS à partir d'une maquette et de manière responsive.",
        "descriptionEn": " Implement a travel booking platform's website from scratch with HTML & CSS.",
        "link": "https://kamoheel.github.io/ch_p2_booki/",
        "year":2022,
        "client": "Booki",
        "roleFr": "Développeuse Front-End - Projet de formation",
        "roleEn": "Front-End Developer - Training project"
    }
]