import { useContext, useState, useRef } from "react";
import { LanguageContext } from "../utils/context";
import emailjs from "@emailjs/browser";

const ContactForm = () => {
    const { language } = useContext(LanguageContext);
    const form = useRef();
    const[errors, setErrors]=useState("");
    const[email, setEmail]=useState("");
    const[firstName, setFirstName]=useState("");
    const[lastName, setLastName]=useState("");
    const[message, setMessage]=useState("");
    const[messageSent, setMessageSent]=useState(false);
    const[sendingError, setSendingError]=useState(false);

    // const regexEmail =
    // /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    const regexEmail =
    /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

  const regexName =
    /^[0-9a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
    const regexMessage =
    /^[0-9a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð .'?!,@$#-_:\n\r]+$/u;

  const handleFirstNameInput = (e) => {
    setFirstName(e.target.value);
    if (regexName.test(e.target.value) || e.target.value.length === 0) {
      setErrors({ firstName: ""});
    } else {
      setErrors({ ...errors, firstName: "Veuillez entrer un nom valide"});
    }
  } 
  
  const handleLastNameInput = (e) => {
    setLastName(e.target.value);
    if (regexName.test(e.target.value) || e.target.value.length === 0) {
      setErrors({ lastName: ""});
    } else {
      setErrors({ ...errors, lastName: "Veuillez entrer un nom valide"});
    }
  }

  const handleEmailInput = (e) => {
    setEmail(e.target.value);
    if (regexEmail.test(e.target.value) || e.target.value.length === 0) {
      setErrors({ email: ""});
    } else {
      setErrors({ ...errors, email: "Veuillez entrer un email valide"});
    }
  }

  const handleMessageInput = (e) => {
    setMessage(e.target.value);
    if (regexMessage.test(e.target.value) || e.target.value.length === 0) {
      setErrors({ message: ""});
    } else {
      setErrors({ ...errors, message: "Veuillez entrer un message valide"});
    }
  }

  const handleSendForm = (e) => {
    e.preventDefault();
    if (
      !regexName.test(lastName) ||
      !regexName.test(firstName) ||
      !regexEmail.test(email) ||
      !regexMessage.test(message) 
    ) {
      return;
    } else {
      emailjs.sendForm(`${process.env.REACT_APP_SERVICE_ID}`, `${process.env.REACT_APP_TEMPLATE_ID}`, form.current, `${process.env.REACT_APP_PUBLIC_KEY}`)
        .then(() => {
          setMessageSent(true);
          setFirstName("");
          setLastName("");
          setEmail("");
          setMessage(""); 
          // window.scrollTo(0, 0);
        })
        .catch(() => {
          setSendingError(true);
        });
    } 
  }

    return ( 
        <div className='contact-form'>
          
        <form name="contact" ref={form} onSubmit={handleSendForm}>
        {/* {language === "french" ? <h3>Formulaire de contact</h3> : <h3>Contact form</h3>} */}
            {language === "french" ? <label htmlFor="lastname">Nom</label> : <label htmlFor="username">Last Name</label>}
            <input type="text" name="user_last_name" id="lastname" value={lastName} onChange={handleLastNameInput} required/>
            <div className='name error'>{errors.lastName}</div>
            {language === "french" ? <label htmlFor="firstname">Prénom</label> : <label htmlFor="username">First Name</label>}
            <input type="text" name="user_first_name" id="firstname" value={firstName} onChange={handleFirstNameInput} required/>
            <div className='name error'>{errors.firstName}</div>
            <label htmlFor="email">Email</label>
            <input type="email" name="user_email" id="email" value={email} onChange={handleEmailInput} required/>
            <div className='email error'>{errors.email}</div>
            <label htmlFor="message">Message</label>
            <textarea name="message" id="message" value={message} onChange={handleMessageInput} required/>
            <div className='message error'>{errors.message}</div>
            {language === "french" ? (
            <div>
              <p className={messageSent ? "confirmation-message" : "hidden"}>Votre message a bien été envoyé, je vous répondrai dans les meilleurs délais!</p>
              <p className={sendingError ? "error-message" : "hidden"}>Une erreur est survenue, veuillez réessayer ou bien envoyer directement un e-mail à <a href="mailto:cimella.web@gmail.com">cimella.web@gmail.com</a>.</p>
          </div>
          ) : (
            <div>
              <p className={messageSent ? "confirmation-message" : "hidden"}>Your message has been sent, I will get back to you ASAP!</p>
              <p className={sendingError ? "error-message" : "hidden"}>An error occured, please try again or send directly an e-mail to <a href="mailto:cimella.web@gmail.com">cimella.web@gmail.com</a>.</p>
          </div>
          )}
            <input type="submit" value={language === "french" ? "Envoyer" : "Send"} className="send-button"/>
        </form>
    </div> 
    );
}
 
export default ContactForm;