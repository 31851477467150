import { useContext } from "react";
import { Link } from "react-router-dom";
import { LanguageContext } from "../utils/context";

const NotFound = () => {
	const { language } = useContext(LanguageContext);

	return ( 
		<section id="notfound">
		<h2 className="notfound-number">404</h2>
        {language === "french" ? 
    	<p className="notfound-text">Oups... La page que vous cherchez n'existe pas.</p> 
		: <p className="notfound-text"> Oops... The page you were looking for does not exist.</p>}
		<Link to="/" className="link">
                    {language === "french" ? <p className="desktop-show">Accueil</p> : <p className="desktop-show">Home Page</p>}
                </Link>
		</section>
	 );
}
 
export default NotFound;
