const PrivacyPolicy = () => {
    return ( <section>
                <div className="policy">
                <h2>Mentions Légales</h2>

                <h3>1 - Édition du site</h3>
                <p>En vertu de l'article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique, il est précisé aux utilisateurs du site www.camilleherpin.com l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi :</p>
                <div className="policy-item">
                    <h4>Propriétaire du site :</h4>
                    <p>Camille Herpin EI </p>
                </div>
                <div className="policy-item">
                    <h4>Contact:</h4>
                    <p>camille.herpin.web@proton.me</p>
                    <p>06 45 77 20 33</p>
                </div>
                <div className="policy-item">
                    <h4>Hébergeur:</h4>
                    <p>Netlify, Inc., 44 Montgomery Street, Suite 300, San Francisco, California 94104</p>
                </div>
                <h3>2 - Propriété intellectuelle et contrefaçons.</h3>
                <p>Camille Herpin est propriétaire des droits de propriété intellectuelle et détient les droits d’usage sur tous les éléments accessibles sur le site internet, notamment les textes, images, graphismes, logos, vidéos, architecture, icônes et sons.
                <br />
                Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable de Camille Herpin.
                <br />
                Toute exploitation non autorisée du site ou de l’un quelconque des éléments qu’il contient sera considérée comme constitutive d’une contrefaçon et poursuivie conformément aux dispositions des articles L.335-2 et suivants du Code de Propriété Intellectuelle.
                </p>
                <h3>3 - Limitations de responsabilité.</h3>
                <p>
                Camille Herpin ne pourra être tenu pour responsable des dommages directs et indirects causés au matériel de l’utilisateur, lors de l’accès au site www.camilleherpin.com.
                <br />
                Camille Herpin décline toute responsabilité quant à l’utilisation qui pourrait être faite des informations et contenus présents sur www.camilleherpin.com.
                <br />
                Camille Herpin s’engage à sécuriser au mieux le site www.camilleherpin.com, cependant sa responsabilité ne pourra être mise en cause si des données indésirables sont importées et installées sur son site à son insu.
                <br />
                Des espaces interactifs (espace contact ou commentaires) sont à la disposition des utilisateurs. Camille Herpin se réserve le droit de supprimer, sans mise en demeure préalable, tout contenu déposé dans cet espace qui contreviendrait à la législation applicable en France, en particulier aux dispositions relatives à la protection des données.
                <br />
                Le cas échéant, Camille Herpin se réserve également la possibilité de mettre en cause la responsabilité civile et/ou pénale de l’utilisateur, notamment en cas de message à caractère raciste, injurieux, diffamant, ou pornographique, quel que soit le support utilisé (texte, photographie …).   
                </p>
                <h3>4 - CNIL et gestion des données personnelles.</h3>
                <p>
                Conformément aux dispositions de la loi 78-17 du 6 janvier 1978 modifiée, l’utilisateur du site www.camilleherpin.com dispose d’un droit d’accès, de modification et de suppression des informations collectées. Pour exercer ce droit, envoyez un message à notre Délégué à la Protection des Données : camille.herpin.web@proton.me .
                </p>
                <h3>5 - Liens hypertextes et cookies.</h3>
                <p>Le site 
www.camilleherpin.com contient des liens hypertextes vers d’autres sites et dégage toute responsabilité à propos de ces liens externes ou des liens créés par d’autres sites vers 
www.camilleherpin.com.</p>
                <h3>6 - Droit applicable et attribution de juridiction.</h3>
                <p>
                Tout litige en relation avec l’utilisation du site www.camilleherpin.com est soumis au droit français. En dehors des cas où la loi ne le permet pas, il est fait attribution exclusive de juridiction aux tribunaux compétents de Toulouse.
                </p>
            </div>
    </section> );
}
 
export default PrivacyPolicy;