export const translations = {
    english: {
        home: "Home",
        about: "About",
        webDev: "Web Developer",
        mainTitle: "Hi, I'm Camille, I am a ",
        mainDesc: " I develop web applications that are ",
        responsives: "responsive",
        dynamic: " dynamic",
        and: " and ",
        accessible: "accessible",
        mainPortfolioLink: "Portfolio",
        mainAboutLink: "About me",
        portfolioTitle: "Previous projects",
        portfolioDesc: "These are the projects I have done so far!",
        aboutTitle: "Wanna Know More?",
        aboutWhoAmI: "Who am I",
        aboutDesc1: "Full-Stack web developer from south of France, near Toulouse. After several experiences abroad, and particularly 3 years working in a medical clinic administration in BC, Canada, I've wanted a job without routine and where I could constantly learn. Having a growing interest in coding, I decided to train to make it my dayly life to create web apps and solve problems, because it is fascinating !",
        aboutDesc2: "End of 2022 marks the end of my Web Developer Training with OpenClassrooms, but overall the beginning of freelancing as a JavaScript Developer.",
        aboutDesc3: "Taking part in a more inclusive and sustainable technology is what motivates me.",
        aboutSkills: "Skills",
        general: "Soft Skills",
        thorough: "Thorough",
        curious: "Curious",
        autonomous:"Independent",
        organized: "Organised",
        creative: "Creative",
        languages: "Languages",
        tools: "Tools",
        libraries: "Libraries",
    },
    french: {
        home: "Accueil",
        about: "À Propos",
        webDev: "Développeuse Web",
        mainTitle: "Bonjour, moi c'est Camille, je suis ",
        mainDesc: " Je développe des sites web ",
        responsives:"responsives",
        dynamic: "dynamiques",
        and: " et ",
        accessible: "accessibles",
        mainPortfolioLink: "Portfolio",
        mainAboutLink: "À Propos",
        portfolioTitle: "Quelques projets",
        portfolioDesc: "Voici quelques exemples de sites que j'ai réalisé.",
        aboutTitle: "Pour en savoir plus...",
        aboutWhoAmI: "Qui suis-je",
        aboutDesc1: "Développeuse web fullstack de la région toulousaine. Après des expériences à l'étranger, et notamment 3 ans dans un service médical administratif pour la province de Colombie-Britannique, j'ai eu envie d'un métier sans routine et où l'on apprend constamment. M'intéressant au développement dans mon temps libre, depuis l'édition de mes mémoires de stage de licence et master en langage LaTeX, j'ai décidé de me reconvertir et de passer mes journées à créer des applications web et résoudre des problèmes, parce que c'est passionnant ! ",
        aboutDesc2: "Fin 2022 marque donc la fin de ma formation 'Développeur Web' réalisée avec OpenClassrooms, mais surtout mes débuts en tant que Développeuse Web freelance. Je suis spécialisée dans le développement JavaScript (React), HTML & CSS. ",
        aboutDesc3: "Participer à une technologie plus inclusive et plus durable, c'est ça qui me motive.",
        aboutSkills: "Compétences",
        general: "Savoir-Être",
        thorough: "Rigoureuse",
        curious: "Curieuse",
        autonomous:"Autonome",
        organized: "Organisée",
        creative: "Créative",
        languages: "Langages",
        tools: "Outils",
        libraries: "Bibliothèques",
    },
  };