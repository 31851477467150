import Divider from '../components/divider';
import { useContext } from "react";
import { NavLink } from 'react-router-dom';
import { LanguageContext } from "../utils/context";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEnvelope, faLocationDot } from "@fortawesome/free-solid-svg-icons";
// import { faPhone } from "@fortawesome/free-solid-svg-icons";
// import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import ContactForm from './contactForm';

const Footer = () => {

    const { language } = useContext(LanguageContext);
    return ( 
        <footer>
            <Divider />
            <h2>Contact</h2>
            <div id="contact">
            {language === "french" ? (
                <p className='contact-text'>N'hésitez-pas à me contacter via le formulaire ci-dessous ou à m'envoyer directement un e-mail à <a href="mailto:camille.herpin.web@proton.me">camille.herpin.web@proton.me</a> .</p>
                ) : (
                    <p className='contact-text'>Do not hesitate to contact me through the contact form below or to send me an email to <a href="mailto:camille.herpin.web@proton.me">camille.herpin.web@proton.me</a> .</p>
                )
            }
            <div className='contact-section'>
                <ContactForm />
                {/* {language === "french" ? <h3>Coordonnées</h3> : <h3>Contact info</h3>}
                <div className='contact-items'>
                    <div className='contact-item'>
                        <FontAwesomeIcon icon={faEnvelope} className="fa" />
                        <a href="mailto:cimella.web@gmail.com">cimella.web@gmail.com</a>
                    </div>
                    <div className='contact-item'>
                        <FontAwesomeIcon icon={faPhone} className="fa" />
                        <a href="tel:0645772033">06 45 77 20 33</a>
                    </div>
                    {/* <div className='contact-item'>
                        <FontAwesomeIcon icon={faLinkedin} className="fa" />
                        {language === "french" ? (<a href="https://www.linkedin.com/in/camille-herpin/">Profil LinkedIn</a>) : (<a href="https://www.linkedin.com/in/camille-herpin/">LinkedIn Profile</a>)}
                    </div> */}
                    {/* <div className='contact-item'>
                        <FontAwesomeIcon icon={faLocationDot} className="fa" />
                        <p>Carbonne, France</p>
                    </div>
                </div> */} 
            </div>
            </div>
            <div className='bottom-banner'>
                {language === "french" ? 
                    ( <p>Copyright © 2022 Camille Herpin | Tous droits réservés | <NavLink to="/privacy-policy"> Mentions légales</NavLink></p> ) : ( <p>Copyright © 2022 Camille Herpin | All rights reserved | <NavLink to="/privacy-policy"> Privacy Policy </NavLink></p> )
                }   
            </div>
        </footer>
     );
}
 
export default Footer;