import { NavLink, Link } from "react-router-dom";
import { useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { LanguageContext } from "../utils/context";
import FrenchFlag from "../assets/french-flag.png";
import UkFlag from "../assets/uk-flag.png";
import MultilingualContent from "../components/multilingualContent";

const Header = () => {
    const [isNavExpanded, setIsNavExpanded] = useState(false);
    const { language, toggleLanguage } = useContext(LanguageContext);
        
    return ( 
        <header>
            <h1 className="main-header-title">
            <div className="allcaps title">Camille Herpin </div>
            {(language === "french") ? <div className="title-smaller">Création de Sites Web</div> : <div className="title-smaller">Websites Creation</div>}
            </h1>
        <nav>
            <button className="menu" onClick={() => {setIsNavExpanded(!isNavExpanded)}} aria-label="Menu Button">
                {isNavExpanded ? <FontAwesomeIcon icon={faXmark} className="fa fa-menu" /> : <FontAwesomeIcon icon={faBars} className="fa fa-menu" />}
            </button>
            <ul className={isNavExpanded ? "navigation-menu expanded" : "navigation-menu"}>
                <NavLink 
                    to="/" 
                    className={({ isActive }) => isActive ? "active-link" : "inactive-link"}
                >
                    <li><MultilingualContent contentID="home" /></li>
                </NavLink>
                {/* <NavLink 
                    to="/web-services" 
                    className={({ isActive }) => isActive ? "active-link" : "inactive-link"}
                >
                    <li>Services</li>
                </NavLink> */}
                <NavLink 
                    to="/portfolio" 
                    className={({ isActive }) => isActive ? "active-link" : "inactive-link"}
                >
                    <li>Portfolio</li>
                </NavLink>
                <NavLink 
                    to="/about"
                    className={({ isActive }) => isActive ? "active-link" : "inactive-link"}
                >
                    <li><MultilingualContent contentID="about" /></li>
                </NavLink>
                <Link onClick={toggleLanguage}>
                    {(language === "french") ? <img src={UkFlag} alt="United Kingdom Flag" className="flag" /> : <img src={FrenchFlag} alt="French Flag" className="flag" />}
                </Link>
            </ul>
        </nav>
     </header>
     );
}
export default Header;