import Divider from '../components/divider';
// import camera from "../assets/camera.png";
// import toucan from "../assets/toucan.png";
// import tree from "../assets/tree.png";
import MultilingualContent from "../components/multilingualContent";
import { useContext } from "react";
import { LanguageContext } from "../utils/context";

const About = () => {
    const { language } = useContext(LanguageContext);
    return ( 
        <div>
            <section className="about">
                {/* <h1><MultilingualContent contentID="aboutTitle" /></h1> */}
                <h2><MultilingualContent contentID="aboutWhoAmI" /></h2>
                <div id="who-am-i">
                {language === "french" ? <div><p>  Je suis une développeuse web passionnée par la création et la découverte de nouvelles choses, originaire du béarn et travaillant actuellement dans la région toulousaine. </p>
                <p>C'est ma curiosité, donc, qui m'a amenée vers la programmation, d'abord pour des projets personnels, puis aujourd'hui afin d'aider de petites entreprises à établir une présence en ligne solide et crédible.</p>
                {/* <p>Mon objectif en tant que développeuse web est de créer des sites web et des applications qui offrent une expérience utilisateur exceptionnelle et qui répondent aux besoins spécifiques de mes clients. Je suis également passionnée par l'apprentissage continu et je cherche toujours à améliorer mes compétences et à rester à la pointe des dernières tendances et technologies.</p> */}
                </div> : <div>
                    <p>  I am a web developer who has a deep passion for creating and discovering new things, originally from Béarn and currently working in the Toulouse region. </p>
                <p>My curiosity brought me into programming, for personal projects at first, and now to help small businesses establish a strong online presence.</p>
                {/* <p>My goal as a web developer is to create websites and applications that offer exceptional user experiences and meet the specific needs of my clients. I am also passionate about continuous learning and always strive to improve my skills and stay up-to-date with the latest trends and technologies.</p> */}
                </div>}
                {/* <MultilingualContent contentID="aboutDesc1" />
                <br />
                <MultilingualContent contentID="aboutDesc2" />
                <br />
                <MultilingualContent contentID="aboutDesc3" /> */}
                    {/* Développeuse web fullstack de la région toulousaine. <br />
                    Après des expériences à l'étranger, et notamment 3 ans dans un service médical administratif pour la province de Colombie-Britannique, j'ai eu envie d'un métier sans routine et où l'on apprend constamment. <br />
                    M'intéressant au développement dans mon temps libre, depuis l'édition de mes mémoires de stage de licence et master en langage LaTeX, j'ai décidé de me reconvertir et de passer mes journées à créer des applications web et résoudre des problèmes, parce que c'est passionnant ! <br />
                    <br />
                    Fin 2022 marque donc la fin de ma formation "Développeur Web" réalisée avec OpenClassrooms, mais surtout le début de ma recherche d'un poste de Développeuse Junior en JavaScript. <br />
                    <br />
                    Participer à une technologie plus inclusive et plus durable, c'est ça qui me motive. */}
                {/* <div id='who-logos'>
                    <img src={tree} alt="Tree Drawing" />
                    <img src={camera} alt="Camera Drawing" />
                    <img src={toucan} alt="Toucan Drawing" />
                    
                </div> */}
                </div>
                </section>
                <Divider />
                <section className="about">
                <h2><MultilingualContent contentID="aboutSkills" /></h2>
                <div className="skills-list">
                    <h3><MultilingualContent contentID="general" /></h3>
                    <ul>
                        <li><MultilingualContent contentID="thorough" /></li>
                        <li><MultilingualContent contentID="curious" /></li>
                        <li><MultilingualContent contentID="autonomous" /></li>
                        <li><MultilingualContent contentID="organized" /></li>
                        <li><MultilingualContent contentID="creative" /></li>
                    </ul>
                </div>
                <div className="divider"></div>
                <div className="skills-list">
                    <h3>Front-end</h3>
                    <ul className="divided-lists">
                        <li className="list"><ul>
                            <h4><MultilingualContent contentID="languages" /></h4>
                            <li>HTML</li>
                            <li>CSS / SCSS / Flexbox</li>
                            <li>JavaScript</li>
                        </ul></li>
                        <li className="list"><ul>
                            <h4><MultilingualContent contentID="tools" /></h4>
                            <li>Webpack / Babel</li>
                            <li>ESLint / Prettier</li>
                            <li>WCAG / SEO</li>
                        </ul></li>
                        <li className="list"><ul>
                            <h4><MultilingualContent contentID="libraries" /></h4>
                            <li>React / Material UI / Routing</li>
                        </ul></li>
                    </ul>
                </div>
                <div className="divider"></div>
                <div className="skills-list">
                    <h3>Back-end</h3>
                    <ul className="divided-lists">
                        <li className="list"><ul>
                            <h4><MultilingualContent contentID="languages" /></h4>
                            <li>NodeJS</li>
                        </ul></li>
                        <li className="list"><ul>
                            <h4><MultilingualContent contentID="tools" /></h4>
                            <li>MongoDB</li>
                            <li>npm</li>
                            <li>REST API</li>
                            <li>OWASP</li>
                        </ul></li>
                        <li className="list"><ul>
                            <h4>Framework</h4>
                            <li>Express.js</li>
                        </ul></li>
                    </ul>
                </div>
            </section>
        </div>
     );
}
 
export default About;